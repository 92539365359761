document.addEventListener("DOMContentLoaded", function() {
    var backButtonContainer = document.querySelector('.back_to_top');

    window.addEventListener('scroll', function() {
        if (window.scrollY > 100) {
            backButtonContainer.style.display = 'flex';
        } else {
            backButtonContainer.style.display = 'none';
        }
    });

    window.scrollToTop = function() {
        var scrollStep = -window.scrollY / (1000 / 15),
            scrollInterval = setInterval(function() {
                if (window.scrollY != 0) {
                    window.scrollBy(0, scrollStep);
                } else {
                    clearInterval(scrollInterval);
                }
            }, 15);
    };
});