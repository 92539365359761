function checkDarkMode() {
    const darkModeQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const darkModeEnabled = localStorage.getItem('theme') === 'dark';
    const systemPrefersDark = darkModeQuery.matches;
    toggleDarkMode(systemPrefersDark || darkModeEnabled);
}

function toggleDarkMode(isDark) {
    const root = document.documentElement;
    const toggleState = isDark ? 'add' : 'remove';

    root.classList.toggle('dark', isDark);
    root.classList.toggle('cc--darkmode', isDark);
    root.classList.toggle('light', !isDark);
    root.classList.toggle('cc--light', !isDark);

    document.querySelectorAll('.theme-toggle').forEach(function (el) {
        el.checked = isDark;
    });

    localStorage.setItem('theme', isDark ? 'dark' : 'light');
}

document.querySelectorAll('.theme-toggle').forEach(function (el) {
    el.addEventListener('change', function () {
        toggleDarkMode(this.checked);
    });
});

checkDarkMode();

window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', function (e) {
    checkDarkMode();
});
